.About {
  margin: 15px 200px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.About img {
  margin-top: 30px;
  height: 600px;
  min-width: 800px;
}

@media (orientation: portrait) {
  .About {
    margin: 10px;
  }

  .About img {
    margin-top: 15px;
    height: 300px;
    min-width: auto;
    max-width: 400px;
  }
}
