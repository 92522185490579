.Navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #07434a;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  flex-wrap: wrap;
  gap: 200px;
  height: 60px;
  font-size: 24px;
}

.Navbar a {
  text-decoration: none;
  text-align: center;
  color: #ecf0f1;
  margin: 0 300px;
  transition: color 0.3s;
}

.Navbar a:hover {
  color: #e74c3c;
}

.Navbar-links {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 1rem;
}

.Navbar-links li {
  flex: 1;
  text-align: center;
  margin: 0 30px;
}

.Navbar-links li a {
  display: block;
  padding: 10px 0;
  color: #fff;
  transition: color 0.3s ease;
}

.Navbar-links li a:hover {
  color: #ff4500;
}

@media (orientation: landscape) {
  .Navbar {
    overflow-x: auto;
    gap: 2rem;
    flex-wrap: wrap;
    height: auto;
  }

  .Navbar a,
  .Navbar-links li {
    flex: 0 0 auto;
    width: auto;
    margin: 0 20px;
  }
}

@media (orientation: portrait) {
  .Navbar {
    flex-direction: row;
    height: auto;
  }

  .Navbar-links {
    gap: 0px;
  }

  .Navbar a,
  .Navbar-links li {
    flex: 0 0 auto;
    width: auto;
    margin: 0 10px;
  }
}
