.Authors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 30px 200px;
  text-align: justify;
  overflow-x: hidden;
}

.Elia {
  height: 360px;
  width: 275px;
}

.Stas {
  height: 360px;
  width: 500px;
}

@media (orientation: portrait) {
  .Authors {
    margin: 10px;
    text-align: center;
  }

  .Authors img {
    max-width: 100%;
    margin-top: 15px;
  }

  .Authors .Stas {
    height: auto;
    width: auto;
  }
}
