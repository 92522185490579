.Reviews {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 15px 200px;
  text-align: justify;
}

h1 {
  align-self: center;
  font-size: 20px;
}

.ReviewsWrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (orientation: portrait) {
  .Reviews {
    margin: 10px;
    text-align: justify;
    overflow: auto;
  }

  .ReviewsWrapper {
    margin: 10px;
  }
}
