.Videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: justify;
  overflow-x: auto;
}

.VideosWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 15px 200px;
}

@media (orientation: portrait) {
  .Videos {
    flex-direction: column;
    height: auto;
    text-align: center;
  }

  .VideosWrapper {
    margin: 10px;
  }
}
