.ImageGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 15px 200px;
}

.ImageGallery img {
  max-width: 100%;
  height: auto;
  width: auto;
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .ImageGallery {
    margin: 10px;
    gap: 10px;
  }
}
